import * as React from "react";
import { memo } from "react";
const SvgLocationWithStar = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 19,
    height: 22,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { clipPath: "url(#4d7116ed-815e-46b5-9e55-70d199408526-a)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      stroke: "#333",
      strokeWidth: 2,
      d: "M17.36 9c0 5.18-7.16 12-8.18 12S1 14.18 1 9c0-4.42 3.66-8 8.18-8a8.1 8.1 0 0 1 8.18 8Z"
    }
  ), /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#333",
      d: "m9.3 5.37.85 2.62q.04.08.12.09h2.75c.12 0 .17.16.07.23l-2.22 1.62s-.06.09-.05.14l.85 2.62c.04.12-.1.21-.19.14l-2.22-1.62s-.1-.03-.15 0l-2.22 1.62c-.1.07-.23-.02-.19-.14l.85-2.62a.1.1 0 0 0-.05-.14L5.28 8.31c-.1-.07-.05-.23.07-.23H8.1q.08 0 .12-.09l.85-2.62c.04-.12.2-.12.24 0z"
    }
  )),
  /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "4d7116ed-815e-46b5-9e55-70d199408526-a" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M0 0h18.36v22H0z" })))
);
const Memo = memo(SvgLocationWithStar);
export default Memo;
